<template>
	<div v-if="show" class="load-page" v-loading="show" element-loading-text="拼命加载中"
		element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.9)">
	</div>
</template>
<script>
	export default {
		data() {
			return {};
		},
		props: {
			show: {
				type: Boolean,
				default: true,
			},
		},
		methods: {},
	};
</script>
<style lang="scss" scoped>
	.load-page {
		width: 100%;
		height: 100vh;
	}
</style>