import { MessageBox } from "element-ui";

// 发版刷新页面，根据监测上传文件实现刷新
let lastSrcs;

//获取最新页面中的script链接
async function extractNewScripts() {
  // _timestamp避免缓存，获取当前时间戳
  const html = await fetch("/?_timestamp=" + Date.now()).then((resp) =>
    resp.text()
  );
  let result = [];
  result = html.match(/src="(.*?)"/g).map((src) => src.slice(5, -1));
  return result;
}

//进行js文件命名对比
async function needUpdate() {
  const newScripts = await extractNewScripts();
  if (!lastSrcs) {
    lastSrcs = newScripts;
    return false;
  }
  let result = false;
  if (lastSrcs.length !== newScripts.length) {
    result = true;
  }
  for (let i = 0; i < lastSrcs.length; i++) {
    if (lastSrcs[i] !== newScripts[i]) {
      result = true;
      break;
    }
  }
  lastSrcs = newScripts;
  return result;
}
//每10秒进行一次比对
const DURATION = 1000 * 10;

function autoRefresh() {
  // 开发环境不检测
  // console.log(process.env.NODE_ENV, "process.env.NODE_ENV");

  if (process.env.NODE_ENV == "development") return;
  setTimeout(async () => {
    // console.log("window.navigator.onLine", window.navigator.onLine);
    // 判断是否离线
    if (window.navigator.onLine) {
      const willUpdate = await needUpdate();
      if (willUpdate) {
        MessageBox.confirm("有新版本啦，快更新ヾ(≧▽≦*)o！！！", "系统提示", {
          confirmButtonText: "立即更新",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          window.location.reload();
        });
      }
    }
    autoRefresh();
  }, DURATION);
}

autoRefresh();
